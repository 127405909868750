import React, { useState } from "react";
import "./Header.css";

import Logo from "../../assets/logo.png";
import Bars from "../../assets/bars.png";

import { Link } from "react-scroll";

const Header = () => {
  const mobile = window.innerWidth <= 768 ? true : false;
  const [menuOpened, setMenuOpened] = useState(false);
  return (
    <div className="header">
      <img alt="logo" src={Logo} className="logo" />

      {!menuOpened && mobile ? (
        <img
          alt="Bars"
          src={Bars}
          className="bars"
          onClick={() => setMenuOpened(!menuOpened)}
        />
      ) : (
        <>
          <ul className="header-menu">
            <li>
              <Link
                to="home"
                spy={true}
                smooth={true}
                onClick={() => setMenuOpened(false)}
              >
                Home
              </Link>{" "}
            </li>
            <li>
              <Link
                to="programs"
                spy={true}
                smooth={true}
                onClick={() => setMenuOpened(false)}
              >
                Programs
              </Link>
            </li>
            <li>
              <Link
                to="reasons"
                spy={true}
                smooth={true}
                onClick={() => setMenuOpened(false)}
              >
                Why us
              </Link>
            </li>
            <li>
              <Link
                to="plans"
                spy={true}
                smooth={true}
                onClick={() => setMenuOpened(false)}
              >
                Plans
              </Link>
            </li>
            <li>
              <Link
                to="testimonials"
                spy={true}
                smooth={true}
                onClick={() => setMenuOpened(false)}
              >
                testimonials
              </Link>
            </li>
          </ul>
        </>
      )}
    </div>
  );
};

export default Header;
